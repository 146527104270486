import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Card, Col, Row } from 'react-bootstrap';
import { ZZZCharacter } from '../../../modules/zzz/common/components/zzz-character';
import {
  faFlask,
  faGavel,
  faHandFist,
  faTrophy
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ZZZRerollGuide: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_reroll.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero (ZZZ) Reroll guide</h1>
          <h2>How to reroll in Zenless Zone Zero (ZZZ) and who to aim for.</h2>
          <p>
            Last updated: <strong>11/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Is it even worth to reroll?" />
        <p>
          The short answers is <strong>no</strong>.
        </p>
        <p>And here's the long answer:</p>
        <ul>
          <li>
            Guest Accounts don't exist in Hoyoverse games anymore which means
            you have to <strong>create a new account</strong> every time you
            want to reroll,
          </li>
          <ul>
            <li>
              So if you're already playing Honkai: Star Rail for example, if you
              plan to reroll, there's no way to have both games on the same
              account.
            </li>
          </ul>
          <li>
            It takes around <strong>25-35 minutes</strong> to finish the
            tutorial and reach the moment when you can actually start pulling,
          </li>
          <ul>
            <li>
              The long time is caused by the tutorial being quite story-heavy
              and you <strong>can't skip any of the conversations</strong> and
              have to click through them,
            </li>
          </ul>
          <li>
            The rates in the game are{' '}
            <strong>
              pretty low (0.3%/0.6% for a{' '}
              <StaticImage
                src="../../../images/zzz/icons/rarity_s.png"
                alt="S Rank"
                className="inline-image"
              />{' '}
              Character)
            </strong>
            , so it might take you even a whole day of rolling to get one - and
            it's high chance it will be someone you don't want as you will be
            able to only do roughly 20-22 pulls when you unlock Signal Search.
          </li>
          <li>
            <strong>
              You are guaranteed to obtain a{' '}
              <StaticImage
                src="../../../images/zzz/icons/rarity_s.png"
                alt="S Rank"
                className="inline-image"
              />{' '}
              Character within the first 50 pulls you do on the Standard banner.
            </strong>
          </li>
        </ul>
        <p>If you're still here, then I think this meme is perfect for you:</p>
        <StaticImage
          src="../../../images/zzz/guides/reroll_pain_zzz.webp"
          alt="Reroll Pain"
        />
        <p>So let's continue with the reroll guide!</p>
        <SectionHeader title="How to reroll" />
        <h5>Reroll methods</h5>
        <p>
          Sadly, there's just one. As we mentioned in the warning section, you
          will need to prepare a ton of dummy emails if you plan to reroll - the
          salting method is blocked, so there's no other way.
        </p>
        <p>So the rerolling process will look like this:</p>
        <ol>
          <li>Log in to the game with new mail,</li>
          <li>
            Suffer for 25-35 minutes and get finger cramps from all the clicking
            through dialogue,
          </li>
          <li>Then suffer even more as you're doing your pulls,</li>
          <li>Log out and log in with new email.</li>
        </ol>
        <p>
          Yes, this is exactly how we had to reroll in Honkai: Star Rail, and
          while you can actually <strong>Skip</strong> some of the story parts
          in ZZZ, it still takes 25-35 minutes to reach the summoning.
        </p>
        <SectionHeader title="How many pulls I will be able to do?" />
        <p>
          While you will unlock the Mail feature quite early and receive{' '}
          <strong>20 Master Tapes</strong> from the pre-register rewards, you
          will have to continue playing the story a bit more to unlock the
          summoning feature. Sadly, the Master Tapes can't be used on Rate-Up
          banners as they require <strong>Encrypted Master Tapes</strong>{' '}
          instead. So you're forced to pull on the Star-Studded Cast (standard
          banner).
        </p>
        <p>
          To get additional tickets, you will have to continue playing the game
          after the tutorial and level up your account.
        </p>
        <h5>Beginner pulls</h5>
        <StaticImage
          src="../../../images/zzz/guides/banner_generic.webp"
          alt="Guide"
        />
        <p>
          Compared to Honkai Star Rail,{' '}
          <strong>
            ZZZ doesn't have a separate newbie banner to do the discounted pulls
            on and you simply pull on the standard one
          </strong>{' '}
          - and your first five 10-pulls are discounted and in total cost you 40
          Master Tapes instead of 50. This also means that you're instantly
          building up pity for the 300 pull selector!
        </p>
        <h5>Rate-up character</h5>
        <p>
          If you want to reroll for the limited character you will only be able
          to do 2-3 pulls on her banner at this point. To get additional pulls,
          you will have to progress deeper into the game. Good luck - you will
          need it! Check our tier list to find out how the current rate-up
          characters compares to other, old characters.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier List"
            link="/zenless/tier-list"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_tier.png"
                alt="Tier List"
              />
            }
          />
        </Row>
        <SectionHeader title="Who to reroll for?" />
        <p>
          Here are the{' '}
          <StaticImage
            src="../../../images/zzz/icons/rarity_s.png"
            alt="S Rank"
            className="faction-icon"
          />{' '}
          <strong>
            <strong className="s-rank">S-Rank</strong>
          </strong>{' '}
          Characters available in the standard pool:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="grace-howard"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="koleda"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="lycaon"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="nekomata"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="soldier-11"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="rina"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          None of the Standard Banner{' '}
          <StaticImage
            src="../../../images/zzz/icons/rarity_s.png"
            alt="S Rank"
            className="faction-icon"
          />{' '}
          <strong>
            <strong className="s-rank">S-Rank</strong>
          </strong>{' '}
          characters are useless in ZZZ and each of them have their own merits
          for being the target of re-rolls. Because of this we’ll be explaining
          each of their pros, cons and value to your account so you can make the
          decision that best suits your account plans. Also, the{' '}
          <strong>
            role the character fills in combat has a big impact on their reroll
            priority
          </strong>{' '}
          as compared to other games, in ZZZ the teams you have to use are
          pretty rigid due to the passive requirements (you either need same
          element or faction in the team to activate them).
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top Ice">
            <p className="name">
              Lycaon [<FontAwesomeIcon icon={faGavel} width="18" /> Stun]
            </p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <ZZZCharacter mode="icon" slug="lycaon" enablePopover />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Lycaon is a{' '}
                <StaticImage
                  src="../../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                  className="faction-icon"
                />{' '}
                <strong>
                  <strong className="s-rank">S-Rank</strong>
                </strong>{' '}
                character from the <strong className="Ice">Ice</strong> element
                who belongs to the <strong>Stun</strong> Specialty.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Additional ability amplifies the standard Stun multiplier from
                  150% to 185% increasing team burst damage during the Stun
                  window tremendously.
                </li>
                <li>
                  Core ability applies 25% Ice Res Shred for 30 seconds allowing
                  for near infinite uptime on enemies boosting the damage of Ice
                  characters by an incredible amount.
                </li>
                <li>
                  Deals massive amounts of Daze unconditionally with his charged
                  basic attack combo allowing him to act as a main field Dazer
                  delivering absurd Daze on field while still having the option
                  to support a Main DPS with Parries and EX’s when they are
                  available.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Charging up basic attacks and chaining them together requires
                  a mild amount of timing to ensure time isn’t wasted in between
                  each attack.
                </li>
                <li>
                  Requires a noticeable amount of field time, while his best DPS
                  units want to be on the field.{' '}
                </li>
                <li>Limited usage out of Ice teams.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Electric">
            <p className="name">
              Rina [<FontAwesomeIcon icon={faFlask} width="18" /> Support]
            </p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <ZZZCharacter mode="icon" slug="rina" enablePopover />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Rina is a{' '}
                <StaticImage
                  src="../../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                  className="faction-icon"
                />{' '}
                <strong>
                  <strong className="s-rank">S-Rank</strong>
                </strong>{' '}
                character from the{' '}
                <strong className="Electric">Electric</strong> element who
                belongs to the <strong>Support</strong> Specialty.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Has two pet Bangboo puppets she casts all her abilities with -
                  they hang around on the field after she casts abilities for a
                  short while before returning to her.
                </li>
                <li>
                  Has the incredibly generic buff that while Bangboos are on the
                  field Rina grants 25% of her PEN ratio + a flat amount up to a
                  limit of 30% to all team members, increasing the whole team’s
                  damage tremendously.
                </li>
                <li>
                  Additional ability increases all Shock durations from 10
                  seconds to 13 seconds resulting in higher Shock uptime and 30%
                  more total damage if all Shocks have the chance to run their
                  full duration (essential for Shock teams). Also grants the
                  team 10% Electric damage while there is a Shocked enemy.
                </li>
                <li>
                  Pen Ratio buff is universal enough to always be helpful in any
                  team and the Shock duration extension is unique enough that
                  it’s unlikely Rina will become replaced too quickly.
                </li>
                <li>
                  Very easy to understand and play outside of ensuring Rina’s
                  Bangboo’s stay deployed.
                </li>
                <li>Very high movement speed.</li>
              </ul>
              <ul className="cons">
                <li>
                  Many abilities last multiple seconds and occupy Rina’s
                  Bangboos, restricting her from casting another ability until
                  the last one finishes - this makes the use of Rina’s abilities
                  in quick succession challenging.
                </li>
                <li>
                  Bangboos need to be constantly redeployed in order to take
                  advantage of Rina’s Pen Ratio buff, meaning you’ll need to
                  stay on top of weaving her attacking in between your other
                  characters as much as possible.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Fire">
            <p className="name">
              Koleda [<FontAwesomeIcon icon={faGavel} width="18" /> Stun]
            </p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <ZZZCharacter mode="icon" slug="koleda" enablePopover />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Koleda is a{' '}
                <StaticImage
                  src="../../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                  className="faction-icon"
                />{' '}
                <strong>
                  <strong className="s-rank">S-Rank</strong>
                </strong>{' '}
                character from the <strong className="Fire">Fire</strong>{' '}
                element who belongs to the <strong>Stun</strong> Specialty.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Gains temporary invincibility during the enhanced portions of
                  her basic attack sequence allowing Koleda to ignore hits and
                  finish her combo instead of dodging.
                </li>
                <li>Very easy to play to her full potential.</li>
                <li>
                  Combining Koleda’s EX with an enhanced basic attack sequence
                  delivers devastating amounts of daze.
                </li>
                <li>
                  Gain’s empowered abilities when in a party with Ben changing
                  and improving some of her kit.
                </li>
                <li>
                  Additional ability (gained by having a Belobog or Fire
                  teammate) is nice but not a dealbreaker to lose making Koleda
                  feel fine to splash into team archetypes she doesn’t have
                  direct synergy in (she can be used in every team without you
                  feeling bad for losing something).
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Has attack animations on the slower side with some attacks
                  that lock her into completing them, sometimes causing Koleda
                  to end up out of position unless you perform a character swap
                  during them.
                </li>
                <li>
                  Non-enhanced basic attacks feel lackluster compared to her
                  EX-enhanced basic combo making her feel somewhat EX-reliant
                  outside of parrying and dodge countering.
                </li>
                <li>
                  Nothing that makes her outstanding outside of her great Daze
                  and being a Fire element makes her subject to power creep.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Fire">
            <p className="name">
              Soldier 11 [<FontAwesomeIcon icon={faHandFist} width="18" /> DPS]
            </p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <ZZZCharacter mode="icon" slug="soldier-11" enablePopover />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Soldier 11 is a{' '}
                <StaticImage
                  src="../../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                  className="faction-icon"
                />{' '}
                <strong>
                  <strong className="s-rank">S-Rank</strong>
                </strong>{' '}
                character from the <strong className="Fire">Fire</strong>{' '}
                element who belongs to the <strong>Attack</strong> Fighting
                Style.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Incredibly simple playstyle once you get the hang of it.
                </li>
                <li>
                  Core Passive and Additional ability are a pile of free damage
                  that doesn’t require you to do anything.
                </li>
              </ul>
              <ul className="cons">
                <li>No stand-out features other than decent damage output,</li>
                <li>
                  Basic attacks have a special “timed” mechanic, requiring you
                  to pause a short moment in between each attack in order to
                  deal maximum damage. This mechanic can be bypassed through the
                  use of some abilities but should still be mastered to unlock
                  Soldier 11’s full potential which won't be for everyone.
                </li>
                <li>Limited synergy with other characters.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Electric">
            <p className="name">
              Grace [<FontAwesomeIcon icon={faHandFist} width="18" /> DPS]
            </p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <ZZZCharacter mode="icon" slug="grace-howard" enablePopover />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Grace is a{' '}
                <StaticImage
                  src="../../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                  className="faction-icon"
                />{' '}
                <strong>
                  <strong className="s-rank">S-Rank</strong>
                </strong>{' '}
                character from the{' '}
                <strong className="Electric">Electric</strong> element who
                belongs to the <strong>Anomaly</strong> Specialty.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Most attacks have a movement-based component which is
                  controllable by the player making Grace one of the most mobile
                  characters.
                </li>
                <li>
                  Incredibly cheap EX Special meaning it will be available far
                  more frequently than other characters.
                </li>
                <li>
                  Incredibly active playstyle requiring accumulation and
                  spending of charges on top of constant movement. Good for
                  players who like a lot of action.
                </li>
                <li>Deals the majority of her damage through Shock.</li>
              </ul>
              <ul className="cons">
                <li>
                  Constant movement on abilities can sometimes lead to Grace
                  being out of position if not controlled correctly.
                </li>
                <li>
                  Re-applying a Shock to an already Shocked target will not deal
                  any additional damage but only refresh the duration making
                  spamming out Shocks, while possible, a less efficient use of
                  Grace's resources (Disorder can be used to solve this issue
                  somewhat).
                </li>
                <li>
                  Less straightforward to play as a sole damage dealer than
                  other characters and prefers to be played alongside another
                  damage dealer (such as Anton) in most cases.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Physical">
            <p className="name">
              Nekomata [<FontAwesomeIcon icon={faHandFist} width="18" /> DPS]
            </p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <ZZZCharacter mode="icon" slug="nekomata" enablePopover />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Nekomata is a{' '}
                <StaticImage
                  src="../../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                  className="faction-icon"
                />{' '}
                <strong>
                  <strong className="s-rank">S-Rank</strong>
                </strong>{' '}
                character from the{' '}
                <strong className="Physical">Physical</strong> element who
                belongs to the <strong>Attack</strong> Specialty.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Has great AoE coverage on her Ultimate, Chain, and Dodge
                  Counter allowing for easy cleanup of weaker enemies.
                </li>
                <li>
                  Multiple team synergies among free-to-play characters even if
                  they aren’t as strong as other characters' best teams.
                </li>
                <li>
                  Incredible mobility with a Dash Attack that is a borderline
                  teleport.
                </li>
                <li>
                  Fast attacking and dodge-based kit perfect for those who enjoy
                  a fast-paced playstyle.
                </li>
                <li>
                  Incredibly cheap EX Special meaning it will be available far
                  more frequently than other characters.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Requires constant use of Dodge Counters or Quick Assists in
                  order to keep her Core passive ability active whereas many
                  other characters get theirs permanently active for free.
                </li>
                <li>
                  During Stun damage windows when the enemy cannot attack,
                  Nekomata must rely on her Support to grant her a Quick Assist
                  or go without a large damage bonus.
                </li>
                <li>Has big competition among her element (Physical).</li>
                <li>Has incredibly limited team synergy options.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <p>Reroll value explanation:</p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="gold" /> -
            The best you can go for,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="silver" /> -
            A versatile character with something unique that makes it stand out,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="bronze" /> -
            Sadly, it's hard to make them work in the current meta, but maybe it
            will change in the future.
          </li>
        </ul>
        <SectionHeader title="Video tips" />
        <p>
          For more information about the standard characters, check this video:
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="C9x6sFMFS-Y" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZRerollGuide;

export const Head: React.FC = () => (
  <Seo
    title="Reroll guide | Zenless Zone Zero | Prydwen Institute"
    description="How to reroll in Zenless Zone Zero and who to aim for."
    game="zzz"
  />
);
